<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Tambah Berita">
      <!-- Title -->
      <div class="d-flex pb-2 border-bottom">
        <b-avatar
          :src="blobImage"
          :text="avatarText('N')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input
              accept="image/png, image/jpg, image/jpeg"
              ref="file"
              type="file"
              style="display: none"
              @change="onFileChange"
            />
            <b-button
              variant="outline-primary small-gap-right"
              @click="$refs.file.click()"
            >
              Upload Foto Baru
            </b-button>
            <b-button @click="blobImage = ''" variant="secondary">
              Reset
            </b-button>
          </div>
          <span>Upload foto berita format jpg/png maks. 800kb</span>
        </div>
      </div>

      <!-- Classroom Config -->
      <div class="mt-2">
        <b-row>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Judul Berita</label>
            <b-form-input
              v-model="formData.newsTitle"
              placeholder="Masukkan Nama Kelas"
            />
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Link External</label>
            <b-form-input
              v-model="formData.newsUrl"
              placeholder="Masukkan Link Eksternal"
            />
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Konten Berita</label>
            <quill-editor v-model="formData.newsContent" />
          </b-col>
        </b-row>
        <div>
          <b-button variant="primary" class="float-right" @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'

// component
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// utils
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText } from '@core/utils/filter'

// quill
import { quillEditor } from 'vue-quill-editor'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BSpinner,
    quillEditor,
  },
  data() {
    return {
      // State
      isLoading: false,
      blobImage: '',

      // Form data
      formData: {
        newsTitle: '',
        newsUrl: '',
        newsContent: '',
        newsCover: '',
      },
    }
  },

  methods: {
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.newsCover = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`

        const data = new FormData()
        data.append('title', this.formData.newsTitle)
        data.append('url', this.formData.newsUrl)
        data.append('content', this.formData.newsContent)
        data.append('image', this.formData.newsCover)
        data.append('status', 'published')

        const config = {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }

        // create classroom
        await axios
          .post(`${baseUrl}/news`, data, config)
          .then((response) => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Berita berhasil dibuat'
            )

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole('news'),
            })
          })
          .catch((error) => {
            this.isLoading = false

            // show error toast
            this.showToast(
              'error',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}
</style>
